<template>
  <div class="bg-cercles">
    <div class="bb-container">
      <div class="bb-login">
        <div class="bb-login__container">
          <div class="login-box">
            <Form class="" :operation="operation" :valid="valid" :error="error">
              <template>
                <div>
                  <h1 class="login-box__title">{{ $t("signup.title", lang) }}</h1>
                </div>
                <div class="login-box__inputs">
                  <InputText
                    :errorLabel="usernameError"
                    :label="$t('signup.yourName', lang) + ':'"
                    :placeholder="$t('signup.yourName', lang)"
                    @focus="usernameError = ''"
                    id="username"
                    v-model="username"
                  />
                  <InputText
                    :errorLabel="emailError"
                    :label="$t('login.yourEmail', lang) + ':'"
                    :placeholder="$t('login.yourEmail', lang)"
                    @focus="emailError = ''"
                    id="email"
                    v-model="email"
                  />
                  <InputText
                    :errorLabel="passwordError"
                    :label="$t('login.password', lang)"
                    :placeholder="$t('login.password', lang)"
                    @focus="passwordError=''"
                    name="password"
                    type="password"
                    v-model="password"
                  />
                  <label class="w-checkbox checkbox-field">
                    <input
                      type="checkbox"
                      id="Privacidad"
                      name="Privacidad"
                      data-name="Privacidad"
                      class="w-checkbox-input"
                      v-model="privacity"
                      @change="privacityError = ''"
                    />
                    <label style="display: inline;" for="Privacidad" class="checkbox-label w-form-label">
                      &nbsp;{{ $t("signup.acceptTCpart1", lang) }}
                      <router-link :to="$t('routes.confidentialityPolicy', lang)" style="text-decoration: underline;" target="_blank"
                      >{{ $t("signup.acceptTCpart2", lang) }}
                      </router-link
                      >
                      .
                    </label>
                  </label>
                  <div v-if="privacityError" class="bb-form-error">
                    {{ privacityError }}
                  </div>
                </div>
              </template>
              <template v-slot:actions>
                <div class="login-box__actions">
                  <input
                    type="submit"
                    :disabled="!valid"
                    :value="$t('signup.cta', lang)"
                    data-wait="Un segundo..."
                    class="button button--primary button--block button--lg"
                  />
                </div>
              </template>
            </Form>
            <login-social
                    :show="true"
                    :showFacebook="false"
                    :showLabel="true"
                    class="login-box__social"
                    color="white"
                    size="large"
                    :origin="origin"
                    :forcedLang="lang"
                  ></login-social>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginMixin from "../mixins/login.js";
import axios from "axios";
import InputText from "../../components/forms/InputText.vue";
import LoginSocial from "../../components/ui/login/LoginSocial.vue";
const crypto = require('crypto');

export default {
  name: "SignUp",
  components: { InputText, LoginSocial },
  mixins: [loginMixin],
  metaInfo() {
    return {
      title: this.$t("signup.metaTitle", this.lang) + " | HelloBB",
      meta: [{
        name: "description",
        content: this.$t("signup.metaDescription", this.lang)
      }],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net/signup" },
        { rel: "alternate", href: "https://www.hellobb.net/creez-votre-liste", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/signup", hreflang: "es-pe" },
      ],
      __dangerouslyDisableSanitizers: ['title', 'meta'],
    };
  },
  data() {
    return {
      username: "",
      usernameError: "",
      password: "",
      passwordError: "",
      email: "",
      emailError: "",
      loading: false,
      accessToken: "",
      userID: "",
      privacity: false,
      privacityError: "",
      valid: true,
      msg: "",
      error: null,
    };
  },
  computed: {
    lang() {
      return this.$route.meta?.lang || this.$i18n.locale;
    },
    origin() {
      const origin = this.$route.query?.origin || "";

      const VALID_ORIGIN = ["signup", "extension"];
      if (origin && VALID_ORIGIN.indexOf(origin) >= 0) {
        return origin;
      }

      return "signup";
    },
  },
  mounted() {
    window.mixpanel.track("Veure pàgina registre");
  },
  methods: {
    async operation() {
      if (this.validations()) {
        await this.signUp();
      }
    },
    validations() {
      if (!this.username) {
        this.usernameError = this.$t("generic.field_is_required", this.lang);
      }

      if (!this.password) {
        this.passwordError = this.$t("generic.field_is_required", this.lang);
      }

      if (!this.email) {
        this.emailError = this.$t("generic.field_is_required", this.lang);
      } else if (!this.$utils.validateEmail(this.email)) {
        this.emailError = this.$t("login.invalidEmailFormat", this.lang);
      }

      if (!this.privacity) {
        this.privacityError = this.$t("signup.mustAcceptTermsAndConditions", this.lang);
      }

      return (
        !this.usernameError &&
        !this.passwordError &&
        !this.emailError &&
        !this.privacityError
      );
    },
    async goToLogin() {
      await this.$router.push({ name: "login" });
    },
    async signUp() {
      const baseURL = process.env.API;
      try {
        await axios.post(baseURL + "/user/signup", {
            email: this.email,
            password: this.password,
            name: this.username,
            signupOrigin: "signup"
          }
        );
        this.setModalShownCookie();

      } catch (error) {
        if (parseInt(error.response?.status) === 409) {
          this.error = this.$t("signup.errorEmailInUse", this.lang);
          await this.$sleep(this.goToLogin, 2000);
          return;
        }
      }

      //window.mixpanel.people.set({ Skipped: false });
      window.mixpanel.people.set("Signup Origin", "signup");
      this.$root.trackConversion({
        email: this.email,
      })

      await this.login("onboarding-1");
    },
    setModalShownCookie() {
      const days = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);

      const currentTimestamp = new Date().getTime().toString();
      const randomValue = Math.random().toString();
      const uniqueHash = crypto.createHash("sha256").update(currentTimestamp + randomValue).digest("hex");

      document.cookie = `modalShown=${uniqueHash}; expires=${expirationDate.toUTCString()}; path=/`;

      // Acceder a la cookie y mostrar su valor en la consola
      const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)modalShown\s*=\s*([^;]*).*$)|^.*$/, "$1");
      console.log('Valor de la cookie modalShown:', cookieValue);
    }


  }

};
</script>
