<template>
  <div class="bb-form-item">
    <!-- Label de l'input  -->
    <div class="bb-form-label" v-if="label" :for="id" :aria-label="label">
      {{ label }}<template v-if="required">*</template>
    </div>
    <div
      class="bb-form__input"
      :class="[
        icoStart || icoEnd || type == 'password' || $slots.logo ? 'bb-form-ico' : '',
      ]"
    >
      <!-- Input només password /////////////////////// -->
      <template v-if="type == 'password'">
        <!-- Input -->
        <input
          class="bb-form bb-form-icoEnd"
          :class="[
            (error || errorLabel) ? 'invalid' : '',
          ]"
          :type="showPassword ? 'text' : 'password'"
          :id="id"
          :name="id"
          :maxlength="maxlength"
          :required="required"
          :placeholder="placeholder"
          :aria-placeholder="placeholder"
          :autocomplete="autocomplete"
          v-model="inputValue"
          :inputmode="inputmode"
          @input="handleInput"
          @focus="$emit('focus')"
        />
        <!-- Show Password -->
        <div class="bb-form-ico__show" @click="togglePasswordVisibility">
          <i class="uil" :class="showPassword ? 'uil-eye-slash' : 'uil-eye'"></i>
        </div>
      </template>

      <!-- Input TOTS /////////////////////// -->
      <template v-else>
        <!-- Icono Start -->
        <div class="bb-form-ico__logo" v-if="$slots.logo">
          <slot name="logo"></slot>
        </div>

        <!-- Logo Start; ej: bizum, paypal... -->
        <div class="bb-form-ico__start" v-if="icoStart">
          <i class="uil" :class="icoStart"></i>
        </div>

        <!-- Input -->
        <input
          class="bb-form"
          :class="[
            size == 'small' ? 'bb-form--small' : '',
            icoStart ? 'bb-form-ico--icoStart' : '',
            icoEnd ? 'bb-form-ico--icoEnd' : '',
            type == 'password' ? 'bb-form-ico--icoEnd' : '',
            $slots.logo ? 'bb-form-ico--icoStart' : '',
            (error || errorLabel) ? 'invalid' : '',
          ]"
          :type="type"
          :id="id"
          :name="id"
          :maxlength="maxlength"
          :required="required"
          :placeholder="placeholder"
          :aria-placeholder="placeholder"
          :autocomplete="autocomplete"
          :disabled="disabled"
          @input="handleInput"
          v-model="inputValue"
          :inputmode="inputmode"
          @focus="$emit('focus')"
        />

        <!-- Icono End -->
        <div class="bb-form-ico__end" v-if="icoEnd">
          <i class="uil" :class="icoEnd"></i>
        </div>
      </template>
    </div>

    <!-- Error labels, per defecte 'error' i personalitzat 'error Label  -->
    <div class="bb-form-error" v-if="error || errorLabel">
      <template v-if="errorLabel">{{ errorLabel }}</template>
      <template v-else>Lo sentimos, el campo es obligatorio</template>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputText",
  data() {
    return {
      password: this.initialValue || "",
      showPassword: false,
      inputValue: this.initialValue || "",
    };
  },
  watch: {
    initialValue(newVal) {
      this.inputValue = newVal;
    },
    deep: true,
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    handleInput() {
      if (this.inputmode === "numeric") {
        this.inputValue = this.inputValue.replace(/[^0-9]/g, '');
      }
      this.$emit("input", this.inputValue);
    },
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: false,
    },
    size: String,
    placeholder: String,
    id: String,
    required: Boolean,
    error: String,
    errorLabel: String,
    icoStart: String,
    icoEnd: String,
    icoLogo: String,
    alignCenter: Boolean,
    maxlength: Number,
    autocomplete: String,
    initialValue: String,
    inputmode: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.invalid {
  border-color: red !important;
}

</style>
